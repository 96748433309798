<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route,
              params: { provider_id: provider_id, id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${loading_provider ? '' : `${provider.name} | `}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.benefits.update"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Tipo"
                    :value="
                      data.benefit_type ? data.benefit_type.benefit_type : ''
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Código" :value="data.code" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'DESCRIPCIONES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(benefit_detail, i) in data.benefit_details"
                  :key="i"
                >
                  <v-row v-if="benefit_detail.active">
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        :label="`Descripción* (${i + 1})`"
                        :value="benefit_detail.description"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <ViewData label="Precio" :value="benefit_detail.amount" />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <ViewData
                        label="Frecuencia"
                        :value="benefit_detail.frequency"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <ViewData
                        label="Tipo"
                        :value="
                          benefit_detail.benefit_type
                            ? benefit_detail.benefit_type.benefit_type
                            : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <ViewData
                        label="Factura única"
                        :value="benefit_detail.bill"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData
                        label="Definición"
                        :value="benefit_detail.definition"
                      />
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'PRESTACIONES INCLUIDAS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(benefit_included, i) in data.benefit_includes"
                  :key="i"
                >
                  <v-row v-if="benefit_included.active">
                    <v-col cols="12">
                      <ViewData
                        :label="`Prestación (${i + 1})`"
                        :value="`${benefit_included.benefit_include.code} | ${benefit_included.benefit_include.benefit_details[0].description}`"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'COBERTURA POR ASEGURADORA'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-for="(
                    benefit_insurance_coverage, i
                  ) in data.benefit_insurance_coverages"
                  :key="i"
                >
                  <v-row v-if="benefit_insurance_coverage.active">
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        :label="`Aseguradora (${i + 1})`"
                        :value="benefit_insurance_coverage.insurance.name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        :label="'Contratante'"
                        :value="
                          benefit_insurance_coverage.contractor
                            ? benefit_insurance_coverage.contractor.name
                            : 'TODOS'
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Cobertura"
                        :value="
                          benefit_insurance_coverage.coverage_type.coverage_type
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        :label="`Aseguradora (${i + 1})`"
                        v-if="benefit_insurance_coverage.provider"
                        :value="benefit_insurance_coverage.provider.trade_name"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BtnCircle
            v-if="login.permissions.benefits.delete"
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, msgConfirm, msgAlert } from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      provider_id: this.$route.params.provider_id,
      page_route: "prestaciones",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: {},
      provider: {},
      loading_provider: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("benefits", this.login.token, this.id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({ name: this.page_route })
                  : console.log(response.message);
              }
            );
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("providers", this.login.token, this.provider_id).then((response) => {
      this.provider = response;
      this.loading_provider = false;
    });
    show("benefits", this.login.token, this.id).then((response) => {
      this.data = response;
      this.loading = false;
    });
  },
};
</script>